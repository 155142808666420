// import logo from './logo.svg';
import LogoFrameBlack from './img/LogoFrameBlack.svg';
import LogoFrameWhite from './img/LogoFrameWhite.svg';

import './App.scss';

import {MenuHeader} from './MenuHeader';
import './MenuHeader.scss';
import {MenuFooter} from './MenuFooter';
import './MenuFooter.scss';

import * as React from 'react';
// import ReactDOM from "react-dom";
// import { BrowserRouter, Route, Switch } from "react-router-dom";

// import * as ReactDOM from "react-dom";
// import {MenuComponent} from '@syncfusion/ej2-react-navigations';
// import { enableRtl } from '@syncfusion/ej2-base';
// Enables Right to left alignment for all controls
// enableRtl(true);
import Home from './Home';
import './Home.scss';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="App-logo-block">
                    <img src={LogoFrameBlack} className="App-logo-frame" alt="logo-frame"/>
                </div>
                <div className="App-header-info">
                    <MenuHeader/>
                    {/*<LogoFrame />*/}
                    <div className="App-button-blue login">Login</div>
                </div>
            </header>
            <div className="App-body">
                <Home/>
            </div>
            <footer className="App-footer">
                <div className="App-logo-block">
                    <img src={LogoFrameWhite} className="App-logo-frame" alt="logo-frame"/>
                    <div className="copyright">&#169;2020</div>
                </div>
                <div className="App-footer-info">
                    <MenuFooter/>
                </div>
            </footer>
        </div>
    );
}

export default App;
