import * as React from "react";
import {MenuComponent} from "@syncfusion/ej2-react-navigations";

export class MenuHeader extends React.Component {
    constructor() {
        super(...arguments);
        this.menuItems = [
            {text: 'About'},
            {
                text: 'Services', items:
                    [
                        {text: 'Order a new card'},
                        {text: 'Transfer from card to card'},
                        {text: 'Reload a w-pay card'},
                    ]
            },
            {
                text: 'Contact'
            },
            {
                text: 'Eng', iconCss: 'e-icon-eng',
                items: [
                    {text: 'English', iconCss: 'e-icon-eng'},
                    {text: 'Hebrew', iconCss: 'e-icon-heb'},
                    {text: 'Russian', iconCss: 'e-icon-rur'},
                    {text: 'Arabic', iconCss: 'e-icon-arb'},
                    {text: 'Thai', iconCss: 'e-icon-tha'},
                ]
            }
        ]
    }

    render() {
        return (<MenuComponent items={this.menuItems}/>);
    }
}

// export const MenuHeader = () => (
//     <MenuHeaderClass/>
// );