import * as React from "react";
// import {Link} from "react-router-dom";

function MenuFooterBlock() {
    return (
        <div className="App-footer-block">
            <div className="App-footer-column">
                <a className="App-footer-row" href="#">About Us</a>
                <a className="App-footer-row" href="#">Services</a>
            </div>
            <div className="App-footer-separator"/>
            <div className="App-footer-column">
                <a className="App-footer-row" href="#">Contact Us</a>
                <a className="App-footer-row" href="#">Fees</a>
            </div>
            <div className="App-footer-separator"/>
            <div className="App-footer-column">
                <a className="App-footer-row" href="#">Regulations</a>
                <a className="App-footer-row" href="#">Questions And Answers</a>
            </div>
            <div className="App-footer-separator"/>
            <div className="App-footer-column">
                <a className="App-footer-row" href="#">Documents for download</a>
                <a className="App-footer-row" href="#">Terms and Conditions</a>
            </div>
        </div>
    );
}

export const MenuFooter = () => (
    <MenuFooterBlock/>
);
