import * as React from "react";
import * as ReactDOM from "react-dom";

// Images
import HomeServicesOrder from './img/HomeServicesOrder.png';
import HomeServicesTransfer from './img/HomeServicesTransfer.png';
import HomeServicesReload from './img/HomeServicesReload.png';
import HomeUse from './img/HomeUse.png';
import HomeSlideUpper from './img/HomeSlideUpper.png';
import RectangleBlue from './img/RectangleBlue.png';
import RectangleCyan from './img/RectangleCyan.png';

// Slides API
import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// Slides images
import SlideLeftBlue from './img/SlideLeftBlue.png';
import SlideRightBlue from './img/SlideRightBlue.png';
import SlideLeftGray from './img/SlideLeftGray.png';
import SlideRightGray from './img/SlideRightGray.png';

import Slide1 from './home-slides/slide1.png';
import Slide2 from './home-slides/slide2.png';
import Slide3 from './home-slides/slide3.png';

//Slides comments
import Quotes from './img/Quotes.png';

import Comment1 from './home-comments/comment1.png';
import Comment2 from './home-comments/comment1.png';
import Comment3 from './home-comments/comment1.png';

const SlideshowImages = () => {

    const slideImages = [
        // '/home-slides/slide1.png',
        // '/home-slides/slide2.png',
        // '/home-slides/slide3.png',
        Slide1,
        Slide2,
        Slide3
    ];


    const properties = {
        // duration: 3000,
        // transitionDuration: 500,
        // infinite: true,
        prevArrow: <img src={SlideLeftBlue} style={{position: "absolute", left: "-80px", top: "50%"}} className="slide-left" alt="slide-left"/>,
        nextArrow: <img src={SlideRightBlue} style={{position: "absolute", right: "-80px", top: "50%"}} className="slide-right" alt="slide-right"/>,
    };

    return (
        <div className="slide-container">
            <Slide {...properties}>
                {
                    slideImages.map((each, index) =>
                        <img key={index} style={{width: "100%"}} src={each}/>
                    )}
            </Slide>
        </div>
    );
}

const SlideshowComments = () => {
    const slideComments = [
        {name: "Angelina North", image: Comment1, text: "This is a wonderful easy to use, VERY EASY yo use service, both for your client as well as staff."},
        // {name: "Angelina North", image: Comment2, text: "This is a wonderful easy to use, VERY EASY yo use service, both for your client as well as staff."},
        // {name: "Angelina North", image: Comment3, text: "This is a wonderful easy to use, VERY EASY yo use service, both for your client as well as staff."},
    ];

    const properties = {
        autoplay: false,
        // duration: 3000,
        // transitionDuration: 500,
        // infinite: true,
        prevArrow: <img src={SlideLeftGray} style={{position: "absolute", left: "-80px", top: "50%"}} className="slide-left" alt="slide-left"/>,
        nextArrow: <img src={SlideRightGray} style={{position: "absolute", right: "-80px", top: "50%"}} className="slide-right" alt="slide-right"/>,
    };


    return (
        <div className="slide-container">
            <Slide {...properties}>
                {
                    slideComments.map((each, index) =>
                        <div className="slide-comment">
                            <img src={Quotes} className="comment-quotes"/>
                            <img key={index} src={each.image} className="comment-face"/>
                            <div className="comment-text">{each.text}</div>
                            <div className="comment-name">{each.name}</div>
                        </div>
                    )}
            </Slide>
        </div>
    );
};

export default class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="App-home">
                <div className="Home-rect"/>
                <div className="Home-info">
                    <div className="Home-find-card">
                        Find the best credit cards for you.
                        <div className="App-button-blue signup">Sign up</div>
                    </div>
                </div>
                <div className="Home-services Home-block">
                    <div className="Home-title App-title-underline">
                        W-Pay Online Services
                    </div>
                    <div className="Home-services-blocks">
                        <div className="Home-services-block">
                            <img src={HomeServicesOrder} style={{top: "-40px"}} alt=""/>
                            <div className="Home-services-name">Order a new card</div>
                        </div>
                        <div className="Home-services-block">
                            <img src={HomeServicesTransfer} style={{top: "-40px"}} alt=""/>
                            <div className="Home-services-name">W-Pay to W-Pay Transfer</div>
                        </div>
                        <div className="Home-services-block">
                            <img src={HomeServicesReload} style={{top: "-60px"}} alt=""/>
                            <div className="Home-services-name">Reload a W-Pay card</div>
                        </div>
                    </div>
                </div>
                <div className="Home-use Home-block">
                    <div className="Home-title App-title-underline">
                        Ways to use W-pay?
                    </div>
                    {/*<div style={{fontSize: "16px", fontWeight: 600, marginTop: "30px"}}>Control your expenses</div>*/}
                    {/*<div style={{fontWeight: 500, fontSize: "14px"}}>*/}
                    {/*    Receive an SMS for every transaction you make.<br/>*/}
                    {/*    Indicating the amount and remaining balance*/}
                    {/*</div>*/}
                    <img src={HomeUse} style={{marginTop: "30px"}} alt=""/>
                </div>
                <div className="Home-slide Home-block Slides-block">
                    <div className="Home-slide-block">
                        <img src={HomeSlideUpper} style={{position: "absolute", right: 0, top: "-185px"}} alt="HomeSlideUpper"/>
                        <img src={RectangleBlue} style={{position: "absolute", left: "-20px", top: "-20px"}} alt="RectangleBlue"/>
                        <img src={RectangleCyan} style={{position: "absolute", right: "-20px", bottom: "-20px"}} alt="RectangleCyan"/>
                        {/*<img src={SlideLeft} style={{position: "absolute", left: "-80px", top: "50%"}} className="slide-left" alt="slide-left" onClick={this.slideLeft}/>*/}
                        {/*<img src={SlideRight} style={{position: "absolute", right: "-80px", top: "50%"}} className="slide-right" alt="slide-right" onClick={this.slideRight}/>*/}
                        <SlideshowImages className="Home-slide-image"/>
                    </div>
                </div>
                <div className="Home-comments Home-block">
                    <div className="Home-title App-title-underline">
                        Comments
                    </div>
                    <div className="Home-comments-block">
                        <div className="Home-comments-bg"/>
                        <SlideshowComments className="Home-slide-comments"/>
                    </div>
                </div>
            </div>
        );
    }
}

// export const Home = () => (
//     <HomeBlock/>
// );
